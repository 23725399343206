<template>
  <campaign-form
    v-if="campaign"
    action="update"
    :loading="loading"
    :updating="updating"
    :data="campaign"
    :error-messages="errorMessages"
    :handle-discard="handleDiscard"
    :handle-submit="handleFormSubmit"
  />
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, computed, provide, getCurrentInstance } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import useCampaignView from './useCampaignView'

import CampaignForm from './campaign-form/CampaignForm.vue'

export default {
  components: {
    CampaignForm,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route } = useRouter()

    const {
      campaign,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useCampaignView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const handleDiscard = () => vm.$router.replace({ name: 'campaign-view', params: { id: route.value.params.id } })
    const handleFormSubmit = formData => updateData(formData)

    return {
      campaign,
      loading,
      updating,
      updateData,

      errorMessages,

      handleDiscard,
      handleFormSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
